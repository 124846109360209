<template>
  <div class="container-fluid">
    <b-row v-if="mode !== 'update'" class="mt-2 mb-2">
      <b-col class="settings-title">
        {{ $t('views.client.new.form.groups') }}
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="mt-2 mb-2">
          <div class="d-flex flex-row align-content-center align-items-center">
            <i class="ml-2 fa fa-group text-danger-light"></i>
            <b-form-input
              v-model="groupName"
              :placeholder="$t('views.client.new.form.new-group')"
              class="ml-4 background-light-blue-inputs custom-input"
              :class="mode === 'update' ? 'w-50' : 'w-25'"
              size="sm"
              @keyup.enter="newGroup()"
            />
            <d-button
              class="d-btn-sm-icon custom-left-borders custom-right-borders font-text-title font-text-title"
              icon="fa fa-check mt-1"
              :class="groupName.trim().length ? 'd-btn-primary' : 'disabled d-btn-danger'"
              @on:button-click="newGroup()"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <template v-for="(group, i) of groups">
      <b-row
        :key="'group' + i"
      >
        <b-col cols="12">
          <div class="mt-2">
            <div class="d-flex flex-row align-content-center align-items-center">
              <span
                :class="!isRecent(group) ? 'text-blue-disabled' : 'text-danger pointer' "
                class="fa fa-minus"
                @click="onMinusClick(group)"
              />
              <vs-checkbox
                color="primary"
                :value="getValue(group)"
                @change="onCheck(group)"
              />
              <span class="ml-3 groups-label"> {{ group.name }} </span>
            </div>
          </div>
        </b-col>
      </b-row>
    </template>
    <d-modal
      :modal-id="'groupModalId'"
      :display="displayDeleteModal"
      :hide="hide"
      text="views.client.remove-group"
      title="views.client.remove-group-title"
      @on:cancel="hide = !hide"
      @on:confirm="removeGroup(selectedGroup)"
    />
  </div>
</template>
<script>
import {deleteGroup, getGroups, postGroup} from "@api/doinsport/services/group/group.api";
import {fromIdToIriReference} from "@/utils/form";

export default {
  data: () => ({
    groups: [],
    selectedGroup: null,
    displayDeleteModal: false,
    hide: false,
    groupName: '',
  }),
  props: {
    client: {
      type: Object,
      default: () => {
      },
    },
    mode: {
      type: String,
      default: 'create'
    }
  },
  methods: {
    onMinusClick(group) {
      if (this.isRecent(group)) {
        this.selectedGroup = group;
        this.displayDeleteModal = !this.displayDeleteModal;
      }
    },
    onCheck(group) {
      const findInGroups = this.client.groups.find(el => el === group['@id']);

      if ('undefined' === typeof findInGroups) {
        this.client.groups.push(group['@id']);
        this.$emit('on:groups-list-updated');
      } else {
        this.client.groups.splice(this.client.groups.indexOf(findInGroups), 1);
        this.$emit('on:groups-list-updated');
      }
    },
    getValue(group) {
      const findInGroups = this.client.groups.find(el => el === group['@id'])

      return 'undefined' === typeof findInGroups ? '' : group['@id'];
    },
    newGroup() {
      if (this.groupName.trim().length > 0) {
        postGroup({
          name: this.groupName,
          type: 'other',
          club: fromIdToIriReference('/clubs', this.$store.getters["auth/currentClub"].id)
        })
          .then((response) => {
            const recentGroup = response.data;
            recentGroup.isRecent = true;
            this.groups.push(recentGroup);
            this.groupName = '';
          })
        ;
      }
    },
    removeGroup(group) {
      if (this.isRecent(group)) {
        this.hide = !this.hide;
        deleteGroup(group.id).then((response) => {
          this.loadGroups();
        })
      }
    },
    isRecent(group) {
      if (group.clients.length === 0) {
        return true;
      }
      if (group.clients.length === 1) {
        return (group.clients[0] === '/clubs/clients/' + this.$route.params.id);
      }
      return false;
    },
    loadGroups() {
      getGroups('other')
        .then((response) => {
          this.groups = response.data['hydra:member'];
          this.$emit('on:loaded-groups', this.groups);
        })
      ;
    }
  },
  created() {
    this.loadGroups();
    this.$vs.theme({
      primary: 'rgb(0, 165, 216)'
    })
  }
}
</script>

<style lang="scss" scoped>
.groups-label {
  font: normal normal 300 17px Avenir;
  letter-spacing: 0px;
  color: #272727;
  opacity: 1;
}

/deep/ .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  font-family: 'Material Icons';
}

/deep/ .form-control-sm {
  border-bottom-right-radius: unset;
  border-top-right-radius: unset;
}

.custom-left-borders {
  border-bottom-left-radius: unset;
  border-top-left-radius: unset;
}

.custom-right-borders {
  border-bottom-right-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.container-credit-scroll {
  position: relative;
  max-height: 100px;
  overflow-x: hidden;
}

/deep/ .ps__rail-y {
  display: block;
  opacity: 1;
}

</style>
